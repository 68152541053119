import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2
  }
});

let LoadingCard = ({ title }) => (
  <div className="uk-card uk-card-default uk-card-body uk-width-1-2@m uk-text-center">
    <div style={{ margin: 50, marginBottom: 80 }}>
      <CircularProgress />
    </div>
    <div style={{ margin: 20, fontSize: 26 }}>{title}</div>
  </div>
);

export default withStyles(styles)(LoadingCard);
