import React from "react";

const RadioInput = ({
  label,
  name,
  value,
  onChange,
  checked,
  indice,
  labelPergunta
}) => (
  <span
    style={{
      marginRight: 20,
      display: "block",
      float: "left",
      marginBottom: 10
    }}
  >
    <label>
      <input
        className="uk-radio"
        type="radio"
        name={name}
        value={label}
        defaultChecked={checked}
        onChange={onChange}
        data-label={labelPergunta}
        data-indice={indice}
      />{" "}
      {label}
    </label>
  </span>
);

export default RadioInput;
