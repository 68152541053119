import {
  UPDATE_VALUE,
  UPDATE_VALUE_ARRAY,
  ADD_LANGUAGE,
  REMOVE_LANGUAGE,
  CLEAR_VALUE,
  SET_FINISH
} from "../actions/actionTypes";

export const updateValue = (
  name,
  value,
  label = null,
  indice = null,
  location = null
) => ({
  type: UPDATE_VALUE,
  payload: {
    name: name,
    label: label,
    value: value,
    indice: indice,
    location
  }
});

export const updateValueArray = (
  name,
  value,
  label = null,
  indice = null,
  location = null
) => ({
  type: UPDATE_VALUE_ARRAY,
  payload: {
    name,
    label,
    value,
    indice,
    location
  }
});

export const clearValue = name => ({
  type: CLEAR_VALUE,
  payload: {
    name: name
  }
});

export const addLanguage = ({ idioma, nivel, diferencial }) => ({
  type: ADD_LANGUAGE,
  payload: {
    idioma,
    nivel,
    diferencial
  }
});
export const removeLanguage = idioma => ({
  type: REMOVE_LANGUAGE,
  payload: idioma
});

export const setFinish = status => ({
  type: SET_FINISH,
  payload: status
});
