import React from "react";

const Checkbox = ({
  label,
  name,
  value,
  onChange,
  checked,
  indice,
  labelPergunta,
  disabled
}) => (
  <span
    style={{
      marginRight: 20,
      display: "block",
      float: "left",
      marginBottom: 10
    }}
  >
    <label>
      <input
        className="uk-checkbox"
        type="Checkbox"
        name={name}
        onChange={onChange}
        value={label}
        checked={checked}
        data-label={labelPergunta}
        data-indice={indice}
        disabled={disabled}
      />{" "}
      {label}
    </label>
  </span>
);

export default Checkbox;
