import React from "react";
import { Link } from "react-router-dom";

const NavBar = ({ hash, match }) => (
  <nav className="uk-navbar uk-navbar-container uk-navbar-transparent">
    <div className="uk-navbar-left">
      <ul className="uk-navbar-nav uk-flex-middle">
        <li>
          <img
            src="http://assets.izap.com.br/perroniconsultoria.com/plus/images?src=tema/plusfiles/2_briefing_8221_perroni-logo.jpg"
            alt="Perroni-logo"
            width="120"
            className="Perroni-logo"
          />
        </li>
        <li>
          <Link to={`${match.url}`} className="menu-link">
            Início
          </Link>
        </li>
        {/* <li>
          <Link to={`${match.url}/debug`}>Debug</Link>
        </li>
        <li>
          <Link to={`${match.url}/concluir`}>Concluir</Link>
        </li> */}
      </ul>
    </div>
    <div className="uk-navbar-center" style={{ fontSize: 20 }}>
      ABERTURA DE VAGA
    </div>
    <div className="uk-navbar-right">
      <ul className="uk-navbar-nav uk-flex-middle" style={{ paddingRight: 20 }}>
        <li>Cód {match.params.hash}</li>
      </ul>
    </div>
  </nav>
);

export default NavBar;
