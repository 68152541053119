import React from "react";
import MaskedInput from "react-text-mask";

const TextInput = ({
  onChange,
  label,
  name,
  value,
  placeholder = "",
  mask = null,
  info = null,
  valid = true,
  indice
}) => (
  <div className="uk-grid uk-grid-medium uk-flex-middle">
    <div className="uk-width-1-3@m uk-text-left">
      <div className="uk-margin-large-left" style={{ color: "#555555" }}>
        {label}
      </div>
    </div>
    <div className="uk-width-1-3@m">
      {mask ? (
        <MaskedInput
          mask={mask}
          type="text"
          className="uk-input"
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          data-label={label}
          data-indice={indice}
        />
      ) : (
        <input
          type="text"
          className="uk-input"
          data-label={label}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          data-indice={indice}
        />
      )}
    </div>
    {info && (
      <div className="uk-width-1-3@m uk-text-left" style={{ fontSize: 12 }}>
        <div className="uk-grid uk-grid-small uk-flex-middle">
          <div className="uk-width-1-6 uk-text-right">
            <span uk-icon="icon: info" />
          </div>
          <div className="uk-width-5-6" style={{ color: "#808080" }}>
            {info}
          </div>
        </div>
      </div>
    )}
  </div>
);

export default TextInput;
