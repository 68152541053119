import React from "react";
import Checkbox from "./Checkbox";

const CheckBoxes = ({
  name,
  value,
  label,
  labelTag,
  checkboxes,
  other = false,
  otherPlaceHolder,
  onChange,
  valueCheckboxes,
  lockValue,
  indice,
  obs
}) => (
  <div className="uk-grid uk-grid-medium uk-flex-top">
    <div className="uk-width-1-3@m uk-text-left">
      <div className="uk-margin-large-left" style={{ color: "#555555" }}>
        {label} {labelTag && <span className="uk-label">{labelTag}</span>}
      </div>
    </div>
    <div className="uk-width-1-3@m">
      {checkboxes.map((c, i) => (
        <div key={i}>
          <Checkbox
            label={c.label}
            labelPergunta={label}
            name={name}
            checked={valueCheckboxes.includes(c.label)}
            value={valueCheckboxes[c.name]}
            onChange={onChange}
            disabled={
              lockValue &&
              lockValue !== c.label &&
              valueCheckboxes.includes(lockValue)
                ? true
                : false
            }
            indice={indice}
          />
        </div>
      ))}
    </div>
    {other && (
      <div className="uk-width-1-3@m uk-text-left" style={{ fontSize: 12 }}>
        <div className="uk-grid uk-grid-small uk-flex-middle">
          <div className="uk-width-1-1" style={{ paddingRight: 20 }}>
            <input
              type="text"
              name={`${name}_outros`}
              value={value}
              className="uk-input"
              onChange={onChange}
              placeholder={
                otherPlaceHolder
                  ? otherPlaceHolder
                  : obs
                  ? "Observações"
                  : "Outros"
              }
              data-label={`${label} (${obs ? "Observações" : "Outros"})`}
              data-indice={indice}
            />
          </div>
        </div>
      </div>
    )}
  </div>
);

export default CheckBoxes;
