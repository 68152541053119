import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setFinish } from "../actions";

let LinkConcluir = ({ setFinish, status }) => {
  const alertFim = () => {
    setFinish(true);
  };
  return (
    <Link
      to="concluir"
      className="uk-button uk-button-primary uk-width-1-1 uk-text-bold"
      onClick={alertFim}
    >
      Revisar Respostas
      {/* {status.finished.toString()} */}
    </Link>
  );
};

const mapStateToProps = store => ({
  status: store.status
});

const mapDispatchToProps = dispatch => ({
  setFinish: status => dispatch(setFinish(status))
});

LinkConcluir = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkConcluir);

export default LinkConcluir;
