import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { updateValue } from "../../actions";

import Title from "../../components/Title";
import PageLinks from "../../components/PageLinks";
import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";
import dataSexo from "../../data/dataSexo";
import dataSimNao from "../../data/dataSimNao";
import dataEstadoCivil from "../../data/dataEstadoCivil";
import dataFaixaEtaria from "../../data/dataFaixaEtaria";
import dataLocalTrabalho from "../../data/dataLocalTrabalho";
import RadioInputs from "../../components/RadioInputs";

const location = "perfilpessoal";

let PerfilPessoal = ({ respostas, updateValue }) => {
  const handleChange = e => {
    console.log(e.target.name, e.target.value);
    const { name, dataset, value } = e.target;
    const { label, indice } = dataset;
    updateValue(name, value, label, indice, location);
  };

  const getResposta = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  return (
    <div>
      <div style={{ minHeight: "75vh" }}>
        <Title step={5} title="Perfil Pessoal" />

        <RadioInputs
          label="Sexo"
          name="candidato_sexo"
          radios={dataSexo}
          onChange={handleChange}
          checked={getResposta("candidato_sexo")}
          value={getResposta("candidato_sexo")}
          indice={1701}
        />

        <RadioInputs
          label="Estado Civil"
          name="candidato_estado_civil"
          radios={dataEstadoCivil}
          onChange={handleChange}
          checked={getResposta("candidato_estado_civil")}
          value={getResposta("candidato_estado_civil")}
          indice={1702}
        />

        <SelectInput
          label="Faixa Etária"
          name="candidato_faixa_etaria"
          options={dataFaixaEtaria}
          value={getResposta("candidato_faixa_etaria")}
          onChange={handleChange}
          indice={1703}
        />

        <RadioInputs
          label="Condução Própria"
          name="candidato_conducao_propria"
          radios={dataSimNao}
          onChange={handleChange}
          checked={getResposta("candidato_conducao_propria")}
          value={getResposta("candidato_conducao_propria_outros")}
          indice={1704}
        />
        <RadioInputs
          label="Disponibilidade para viagens"
          name="candidato_disp_viagens"
          radios={dataSimNao}
          onChange={handleChange}
          other
          obs
          checked={getResposta("candidato_disp_viagens")}
          value={getResposta("candidato_disp_viagens_outros")}
          indice={1705}
        />
        <RadioInputs
          label="Disponibilidade para mudança"
          name="candidato_disp_mudanca"
          other
          obs
          radios={dataSimNao}
          onChange={handleChange}
          checked={getResposta("candidato_disp_mudanca")}
          value={getResposta("candidato_disp_mudanca_outros")}
          indice={1706}
        />
        <RadioInputs
          label="Local de Trabalho"
          name="candidato_local_trabalho"
          other
          placeholderOther="Endereço"
          radios={dataLocalTrabalho}
          onChange={handleChange}
          checked={getResposta("candidato_local_trabalho")}
          value={getResposta("candidato_local_trabalho_outros")}
          indice={1707}
        />

        <TextInput
          label="Horário de Trabalho"
          name="candidato_horario_trabalho"
          value={getResposta("candidato_horario_trabalho")}
          onChange={handleChange}
          indice={1708}
        />
      </div>

      <PageLinks nextRoute="remuneracao" prevRoute="reqvaga3" />
    </div>
  );
};

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location))
});

PerfilPessoal = connect(
  mapStateToProps,
  mapDispatchToProps
)(PerfilPessoal);

export default PerfilPessoal;
