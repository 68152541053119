import React from "react";
import { Link } from "react-router-dom";

const LinkNext = ({ to, label, theEnd }) => (
  <div
    style={{ marginTop: 40, paddingRight: 60 }}
    className="uk-text-right uk-width-1-3"
  >
    <Link
    onClick={window.scrollTo(0,0)}
      to={to}
      className="uk-button uk-button-primary uk-width-4-5 uk-text-bold uk-text-right"
    >
      {label ? label : "PRÓXIMA"}
      {!theEnd && (
        <span
          className="uk-margin-small-right uk-margin-large-left"
          style={{ fontWeight: "400" }}
          uk-icon="icon: arrow-right; ratio: 1.8"
        />
      )}
    </Link>
  </div>
);

export default LinkNext;
