import React from "react";
import { connect } from "react-redux";
import { updateValue, updateValueArray } from "../../actions";
import _ from "lodash";

import PageLinks from "../../components/PageLinks";
import Title from "../../components/Title";

import TextInput from "../../components/TextInput";
import CheckBoxes from "../../components/CheckBoxes";
import RadioInputs from "../../components/RadioInputs";

const dataSimNao = [{ label: "Sim", value: 1 }, { label: "Não", value: 0 }];
const dataNecessidade = [
  { label: "Desejada", value: 1 },
  { label: "Obrigatória", value: 0 }
];
const dataClassificaEstratagica = [
  { label: "Valores das Ações", name: "atuacao_estrategica_valor", value: 0 },
  {
    label: "Complexidade da Matéria",
    name: "atuacao_estrategica_complexidade",
    value: 0
  },
  {
    label: "Relevância do cliente para a empresa/escritório",
    name: "atuacao_estrategica_relevcliente",
    value: 0
  }
];
const dataClassifica = [
  { label: "Massificado", name: "atuacao_massificado", value: 0 },
  {
    label: "Volume intermediário",
    name: "atuacao_volintermed",
    value: 0
  },
  {
    label: "Matéria de baixa ou média complexidade",
    name: "atuacao_complex_baixaoumedia",
    value: 0
  }
];

const dataPorteArea = [
  { label: "Pequeno", name: "porte_clientes_pequeno", value: 1 },
  { label: "Médio", name: "porte_clientes_medio", value: 2 },
  { label: "Grande", name: "porte_clientes_grande", value: 3 },
  { label: "Internacional", name: "porte_clientes_internacional", value: 4 }
];
const dataReqEspecializacoes = [
  { label: "Especialização", name: "req_especializacao", value: 1 },
  { label: "Mestrado", name: "req_mestrado", value: 2 },
  { label: "Doutorado", name: "req_doutorado", value: 3 }
];
const dataExpMin = [
  { label: "Sem Experiência", value: 1 },
  { label: "até 6 meses", value: 2 },
  { label: "de 6 meses a 1 ano", value: 3 },
  { label: "de 1 a 2 anos", value: 4 },
  { label: "de 2 a 3 anos", value: 5 },
  { label: "de 3 a 4 anos", value: 6 },
  { label: "de 4 a 5 anos", value: 7 },
  { label: "de 5 a 6 anos", value: 8 },
  { label: "de 7 a 10 anos", value: 9 },
  { label: "de 10 a 15 anos", value: 10 },
  { label: "Acima de 15 anos", value: 11 }
];

const location = "atividades2";

let Atividades2 = ({ respostas, updateValue, updateValueArray }) => {
  const handleChange = e => {
    console.log(e.target);
    const { name, dataset, value } = e.target;
    // const value =
    //   e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const { label, indice } = dataset;
    if (e.target.type === "checkbox") {
      updateValueArray(name, value, label, indice, location);
    } else {
      updateValue(name, value, label, indice, location);
    }
  };

  const getResposta = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  const getRespostasCheckbox = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return [];
  };

  return (
    <div>
      <div style={{ minHeight: "75vh" }}>
        <Title step="3" part="2" title="Atividades do Profissional" />

        <RadioInputs
          label="Atuação Estratégica?"
          name="atuacao_estrategica"
          radios={dataSimNao}
          onChange={handleChange}
          checked={getResposta("atuacao_estrategica")}
          value={getResposta("atuacao_estrategica")}
          indice={1201}
        />

        {getResposta("atuacao_estrategica") === "Sim" && (
          <CheckBoxes
            label="Como classifica a atuação Estratégica?"
            other
            name="classif_atuacao_estrategica"
            checkboxes={dataClassificaEstratagica}
            valueCheckboxes={getRespostasCheckbox(
              "classif_atuacao_estrategica"
            )}
            value={getResposta("classif_atuacao_estrategica_outros")}
            onChange={handleChange}
            indice={1202}
          />
        )}

        {getResposta("atuacao_estrategica") === "Não" && (
          <CheckBoxes
            label="Como classifica a atuação?"
            other
            name="classif_atuacao"
            checkboxes={dataClassifica}
            valueCheckboxes={getRespostasCheckbox("classif_atuacao")}
            value={getResposta("classif_atuacao_outros")}
            onChange={handleChange}
            indice={1203}
          />
        )}

        <RadioInputs
          label="O Profissional terá contato com o Cliente?"
          name="vaga_req_contatocliente"
          radios={dataSimNao}
          onChange={handleChange}
          checked={getResposta("vaga_req_contatocliente")}
          value={getResposta("vaga_req_contatocliente")}
          indice={1204}
        />

        <CheckBoxes
          label="Porte dos Clientes atendidos pela área"
          checkboxes={dataPorteArea}
          name="porte_clientes"
          valueCheckboxes={getRespostasCheckbox("porte_clientes")}
          onChange={handleChange}
          indice={1205}
        />

        <CheckBoxes
          label="Tempo de experiência desejado que o profissional tenha nas atividades descritas anteriormente"
          checkboxes={dataExpMin}
          name="vaga_req_expmin"
          valueCheckboxes={getRespostasCheckbox("vaga_req_expmin")}
          onChange={handleChange}
          indice={1206}
        />

        <RadioInputs
          label="Tempo de Estágio será considerado?"
          name="considerar_experiencia_estagio"
          radios={dataSimNao}
          onChange={handleChange}
          checked={getResposta("considerar_experiencia_estagio")}
          value={getResposta("considerar_experiencia_estagio")}
          indice={1207}
        />

        <CheckBoxes
          label="Formação Acadêmica"
          checkboxes={[
            {
              label: "Graduação completa em Direito"
            }
          ]}
          name="form_direito"
          onChange={handleChange}
          valueCheckboxes={getRespostasCheckbox("form_direito")}
          indice={1208}
        />

        <TextInput
          label="Formações Complementares ou Adicionais"
          name="formacoes_complementares"
          onChange={handleChange}
          value={getResposta("formacoes_complementares")}
          indice={1209}
        />

        <CheckBoxes
          label="Especialização esperada do profissional"
          checkboxes={dataReqEspecializacoes}
          name="especializacao"
          other
          obs
          onChange={handleChange}
          valueCheckboxes={getRespostasCheckbox("especializacao")}
          value={getResposta("especializacao_outros")}
          indice={1210}
        />

        {getResposta("especializacao").length > 0 && (
          <RadioInputs
            label="A Especialização é"
            name="especializacao_necessidade"
            radios={dataNecessidade}
            onChange={handleChange}
            checked={getResposta("especializacao_necessidade")}
            value={getResposta("especializacao_necessidade")}
            indice={1211}
          />
        )}

        <RadioInputs
          label="Tempo mínimo de formação do profissional para este cargo"
          name="vaga_req_form_min"
          radios={dataExpMin}
          onChange={handleChange}
          checked={getResposta("vaga_req_form_min")}
          value={getResposta("vaga_req_form_min")}
          indice={1212}
        />
      </div>

      <PageLinks nextRoute="reqvaga2" prevRoute="atividades1" />
    </div>
  );
};

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location)),
  updateValueArray: (name, value, label, indice, location) =>
    dispatch(updateValueArray(name, value, label, indice, location))
});

Atividades2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Atividades2);

export default Atividades2;
