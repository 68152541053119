import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactJson from "react-json-view";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPercentage } from "@fortawesome/free-solid-svg-icons";

import "./App.css";
import Debug from "./Debug";
import NavBar from "./pages/NavBar";
import Home from "./pages/Home";
import RespVaga from "./pages/passos/RespVaga";
import SobreVaga from "./pages/passos/SobreVaga";
import Atividades1 from "./pages/passos/Atividades1";
import Atividades2 from "./pages/passos/Atividades2";
import ReqVaga from "./pages/passos/ReqVaga";
import ReqVaga2 from "./pages/passos/ReqVaga2";
import ReqVaga3 from "./pages/passos/ReqVaga3";
import AtuaCrescimento from "./pages/passos/AtuaCrescimento";
import PerfilPessoal from "./pages/passos/PerfilPessoal";
import Remuneracao from "./pages/passos/Remuneracao";
import Comportamental from "./pages/passos/Comportamental";
import Complementacao from "./pages/passos/Complementacoes";
import Conclusao from "./pages/passos/Conclusao";
import LoadRespostas from "./components/LoadRespostas";

library.add(faPercentage);

const Main = ({ match }) => (
  <div>
    <NavBar hash={match.params.hash} match={match} />
    <LoadRespostas hash={match.params.hash} match={match} />
    {/* <ReactJson src={match} /> */}

    <Route exact path={`${match.url}/`} component={Home} />
    <Route path={`${match.url}/debug/`} component={Debug} />

    <Route path={`${match.path}respvaga`} component={RespVaga} />
    <Route path={`${match.path}sobrevaga`} component={SobreVaga} />
    <Route path={`${match.path}atividades1`} component={Atividades1} />
    <Route path={`${match.path}atividades2`} component={Atividades2} />
    <Route path={`${match.path}reqvaga`} component={ReqVaga} />
    <Route path={`${match.path}reqvaga2`} component={ReqVaga2} />
    <Route path={`${match.path}reqvaga3`} component={ReqVaga3} />
    <Route path={`${match.path}atuacrescimento`} component={AtuaCrescimento} />
    <Route path={`${match.path}perfilpessoal`} component={PerfilPessoal} />
    <Route path={`${match.path}remuneracao`} component={Remuneracao} />
    <Route path={`${match.path}comportamental`} component={Comportamental} />
    <Route path={`${match.path}complementacao`} component={Complementacao} />
    <Route path={`${match.path}concluir`} component={Conclusao} />
    {/* <Route
      exact
      path={match.path}
      render={() => <h3>Please select a topic.</h3>}
    /> */}
  </div>
);

const Routes = ({ match }) => (
  <Router>
    <div className="App">
      <Route path="/:hash/" component={Main} />
    </div>
  </Router>
);

export default Routes;
