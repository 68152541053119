import { ADD_LANGUAGE, REMOVE_LANGUAGE } from "../actions/actionTypes";
import _ from "lodash";

export const idiomasReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_LANGUAGE:
      let respostas1 = _.filter(
        state,
        resp => resp.idioma !== action.payload.idioma
      );
      return [...respostas1, action.payload];
    case REMOVE_LANGUAGE:
      let respostas2 = _.filter(
        state,
        resp => resp.idioma !== action.payload.idioma
      );
      return [...respostas2];
    default:
      return state;
  }
};
