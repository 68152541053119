import React from "react";

const TextAreaInput = ({
  label,
  name,
  value,
  info = null,
  valid = true,
  onChange,
  indice,
  rows
}) => (
  <div className="uk-grid uk-grid-medium uk-flex-top">
    <div className="uk-width-1-3@m uk-text-left">
      <div className="uk-margin-large-left">{label}</div>
    </div>
    <div className={info ? "uk-width-2-5@m" : "uk-width-3-5@m"}>
      <textarea
        rows={rows ? rows : 4}
        className="uk-textarea"
        name={name}
        onChange={onChange}
        value={value}
        data-label={label}
        data-indice={indice}
      />
    </div>
    {info && (
      <div className="uk-width-1-5@m uk-text-left" style={{ fontSize: 12 }}>
        <div className="uk-grid uk-grid-small uk-flex-middle">
          <div className="uk-width-1-6 uk-text-right">
            <span uk-icon="icon: info" />
          </div>
          <div className="uk-width-5-6" style={{ color: "#808080" }}>
            {info}
          </div>
        </div>
      </div>
    )}
  </div>
);

export default TextAreaInput;
