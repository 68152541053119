import React, { Component } from "react";
import { connect } from "react-redux";
import LoadingCard from "../../components/LoadingCard";
import axios from "axios";
import { Link } from "react-router-dom";

import Title from "../../components/Title";

function nl2br(str, is_xhtml) {
  var breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
}

class Conclusao extends Component {
  state = {
    isLoading: false,
    finished: false,
    duplicado: false
  };
  sortRespostas = () => {
    const { respostas } = this.props;

    return respostas.sort((a, b) => a.indice - b.indice);
  };

  handleConcluir = () => {
    this.setState({ isLoading: true }, () => {
      var data = {
        respostas: this.props.respostas,
        idiomas: this.props.idiomas,
        hash: this.props.match.params.hash
      };
      // axios.post(`/api/aberturavaga`, data).then(resp => {
      axios
        .post(`https://sistema.perroniconsultoria.com/api/aberturavaga`, data)
        // .post(`http://perroni.test/api/aberturavaga`, data)
        .then(resp => {
          if (resp.data.status === "success") {
            this.setState({ isLoading: false, finished: true });
          } else if (resp.data.status === "respondido") {
            this.setState({ isLoading: false, finished: true, duplicado: true });
          }
        });
    });
  };

  displayValue = value => {
    let printable = "";
    if (value.constructor == Array) {
      value.forEach((v, i) => {
        if (i === 0) {
          printable = v;
        } else {
          printable = `${printable}, ${v}`;
        }
      });

      return printable;
    }

    return value;
  };

  render() {
    const { idiomas } = this.props;
    const { isLoading, finished, duplicado } = this.state;

    if (duplicado) {
      return (
        <div>
          <div style={{ marginLeft: 120, marginRight: 120 }}>
            <Title title="ATENÇÃO!" />

            <p style={{ fontSize: 24 }}>
              Identificamos que esse formulário já foi preenchido, por tanto não existe a necessidade novo preenchimento.
              Caso seja necessário uma nova abertura de vaga, entre em contato com os Administradores da Perroni para gerar
              um novo link de preenchimento.
            </p>
          </div>
        </div>
      );
    }

    if (finished) {
      return (
        <div>
          <div style={{ marginLeft: 120, marginRight: 120 }}>
            <Title title="Obrigado" />

            <p style={{ fontSize: 24 }}>
              Os dados informados já constam em nossa base de dados e o
              responsável pelo seu processo foi notificado.
            </p>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div style={{ marginLeft: 60, marginRight: 60 }}>
          <Title title="Revisão" />

          {isLoading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: 40
              }}
            >
              <LoadingCard title="Aguarde enquanto processamos os dados informados." />
            </div>
          )}

          <ul className="uk-list uk-list-divider">
            {this.sortRespostas().map((r, i) => (
              <li key={i}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <div
                    style={{
                      flex: 0.35,
                      fontWeight: "bold",
                      padding: 20,
                      textAlign: "justify"
                    }}
                  >
                    {r.label}
                  </div>
                  <div
                    style={{ flex: 0.55, textAlign: "left", padding: 20 }}
                    dangerouslySetInnerHTML={{
                      __html: nl2br(this.displayValue(r.value))
                    }}
                  />
                  <div
                    style={{
                      flex: 0.1
                    }}
                  >
                    {r.location && (
                      <Link
                        to={r.location}
                        className="uk-button uk-link"
                        style={{ textAlign: "center", color: "orange" }}
                      >
                        <span uk-icon="pencil" />
                      </Link>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>

          {idiomas.length > 0 && <h3>Idiomas</h3>}

          <ul className="uk-list uk-list-divider">
            {idiomas.map((r, i) => (
              <li key={i}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <div style={{ flex: 0.2, textAlign: "left" }}>{r.idioma}</div>
                  <div
                    style={{
                      flex: r.diferencial ? 0.3 : 0.6,
                      textAlign: "left"
                    }}
                  >
                    {r.nivel}
                  </div>
                  {r.diferencial && (
                    <div style={{ flex: 0.3, textAlign: "left" }}>
                      Não é necessário mas considero um diferencial
                    </div>
                  )}
                  <div
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Link
                      to="reqvaga2"
                      className="uk-button uk-link"
                      style={{ textAlign: "center", color: "orange" }}
                    >
                      <span uk-icon="pencil" />
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <button
          onClick={this.handleConcluir}
          disabled={this.isLoading}
          className="uk-button uk-button-large uk-button-primary uk-text-bold"
        >
          {this.isLoading ? "AGUARDE UM INSTANTE" : "CONCLUIR PROCESSO"}
        </button>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  respostas: store.respostas,
  idiomas: store.idiomas,
  status: store.status
});

Conclusao = connect(mapStateToProps)(Conclusao);

export default Conclusao;
