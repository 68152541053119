import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { updateValue } from "../../actions";

import TextInput from "../../components/TextInput";
import Title from "../../components/Title";
import PageLinks from "../../components/PageLinks";
import Checkboxes from "../../components/CheckBoxes";
import RadioInputs from "../../components/RadioInputs";

const dataPorteArea = [
  { label: "Pequeno", value: 1 },
  { label: "Médio", value: 2 },
  { label: "Grande", value: 3 },
  { label: "Internacional", value: 3 }
];
const dataReqEspecializacoes = [
  { label: "Especialização", name: "req_especializacao", value: 1 },
  { label: "Mestrado", name: "req_mestrado", value: 2 },
  { label: "Doutorado", name: "req_doutorado", value: 3 }
];
const dataExpMin = [
  { label: "Estágio", value: 1 },
  { label: "6 meses", value: 2 },
  { label: "1 a 2 anos", value: 3 },
  { label: "2 a 3 anos", value: 4 },
  { label: "3 a 4 anos", value: 5 },
  { label: "4 a 5 anos", value: 6 },
  { label: "5 a 6 anos", value: 7 },
  { label: "6 a 7 anos", value: 8 },
  { label: "7 a 10 anos", value: 9 },
  { label: "Acima de 10 anos", value: 10 }
];

let ReqVaga = ({ respostas, updateValue }) => {
  const handleChange = e => {
    // console.log(e.target.name, e.target.value);
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const { name } = e.target;
    console.log(name, value);
    updateValue(name, value);
  };

  const getResposta = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  const getRespostasCheckbox = checkboxes => {
    let values = {};
    checkboxes.forEach(c => {
      values[c.name] = getResposta(c.name);
    });

    // console.log(values);
    return values;
  };

  return (
    <div>
      <div style={{ minHeight: "75vh" }}>
        <Title step="4" title="Requisitos para a Vaga" part={1} />

        <RadioInputs
          label="Porte dos Clientes atendidos pela área"
          name="vaga_req_portearea"
          radios={dataPorteArea}
          onChange={handleChange}
          checked={getResposta("vaga_req_portearea")}
          value={getResposta("vaga_req_portearea_outros")}
        />
        <RadioInputs
          label="Tempo de experiência mínimo do profissional nas atividades descritas anteriormente"
          name="vaga_req_expmin"
          radios={dataExpMin}
          onChange={handleChange}
          checked={getResposta("vaga_req_expmin")}
          value={getResposta("vaga_req_expmin_outros")}
        />

        <TextInput
          label="Formação Acadêmica"
          name="vaga_req_formacao"
          onChange={handleChange}
          value={getResposta("vaga_req_formacao")}
        />

        <Checkboxes
          label="Especialização"
          checkboxes={dataReqEspecializacoes}
          name="especializacao"
          other
          onChange={handleChange}
          valueCheckboxes={getRespostasCheckbox(dataReqEspecializacoes)}
          value={getResposta("especializacao")}
        />
      </div>

      <PageLinks prevRoute="atividades2" nextRoute="reqvaga2" />
    </div>
  );
};

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value) => dispatch(updateValue(name, value))
});

ReqVaga = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReqVaga);

export default ReqVaga;
