import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { updateValue, updateValueArray } from "../../actions";
import axios from "axios";

import Select from "react-select";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import Title from "../../components/Title";
import PageLinks from "../../components/PageLinks";
import RadioInputs from "../../components/RadioInputs";

import dataNivel from "../../data/dataNivel.json";
import dataMotivoAbertura from "../../data/dataMotivoAbertura.json";
import dataAreasJur from "../../data/areasjur.json";

const dataSimNao = [{ label: "Sim", value: 1 }, { label: "Não", value: 0 }];
const dataQtdVagas = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 }
];

class SobreVaga extends Component {
  state = {
    areasjur: [],
    location: "sobrevaga"
  };

  componentDidMount() {
    let areas = [];
    dataAreasJur.map(a => {
      areas.push({
        value: a.descr,
        label: a.descr,
        name: "areas_jur",
        pergunta: "Área Jurídica",
        indice: 1012
      });
    });

    this.setState({ areasjur: areas });
    // const url = "/api/areasJur";

    // axios.get(url).then(response => {
    //   let areas = [];
    //   response.data.map(a => {
    //     areas.push({
    //       value: a.descr,
    //       label: a.descr,
    //       name: "areas_jur",
    //       pergunta: "Área Jurídica",
    //       indice: 1012
    //     });
    //   });

    //   this.setState({ areasjur: areas }, () => console.log(this.state));
    // });
  }

  handleChange = e => {
    // console.log(e);
    const { name, value, dataset } = e.target;
    const { label, indice } = dataset;
    this.props.updateValue(name, value, label, indice, this.state.location);
  };

  getResposta = name => {
    const { respostas } = this.props;
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  getRespostaMultiSelect = name => {
    const { respostas } = this.props;
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      let options = [];
      _.forEach(resposta.value, resp => {
        options.push({
          indice: 1012,
          value: resp,
          label: resp,
          name,
          pergunta: "Área Jurídica"
        });
      });
      return options;
    }
    return [];
  };

  handleSelectArea = (a, b) => {
    if (b.action === "remove-value") {
      let { name, value, indice, pergunta } = b.removedValue;
      this.props.updateValueArray(
        name,
        value,
        pergunta,
        indice,
        this.state.location
      );
    } else if (b.action === "select-option") {
      let { name, value, indice, pergunta } = b.option;
      this.props.updateValueArray(
        name,
        value,
        pergunta,
        indice,
        this.state.location
      );
    }
  };

  render() {
    const { areasjur } = this.state;
    return (
      <div>
        <div style={{ minHeight: "75vh" }}>
          <Title step="2" title="Informações sobre a Vaga" />

          <SelectInput
            label="Quantidade de Vagas"
            name="vaga_qtd"
            options={dataQtdVagas}
            value={this.getResposta("vaga_qtd")}
            onChange={this.handleChange}
            info="Preencha com 2 ou mais apenas se as vagas forem para o mesmo perfil"
            indice={1001}
          />

          <TextInput
            label="Cargo"
            // info="Ex: Advogado Civil, Advogado Trabalhista, Advogado Tributário, Consultor TAX, etc."
            name="vaga_cargo"
            indice={1010}
            value={this.getResposta("vaga_cargo")}
            onChange={this.handleChange}
          />

          <SelectInput
            label="Forma de Contratação"
            name="forma_contratacao"
            options={[
              { label: "CLT" },
              { label: "Estágio" },
              { label: "Associado" },
              { label: "PJ" },
              { label: "Sócio de Serviço" },
            ]}
            value={this.getResposta("forma_contratacao")}
            onChange={this.handleChange}
            indice={1011}
          />

          <div style={{ marginBottom: 30, marginTop: 30 }}>
            <div className="uk-grid uk-grid-medium uk-flex-middle">
              <div className="uk-width-1-3@m uk-text-left">
                <div
                  className="uk-margin-large-left"
                  style={{ color: "#555555" }}
                >
                  Área Jurídica
                </div>
              </div>
              <div className="uk-width-1-3@m">
                <Select
                  value={this.getRespostaMultiSelect("areas_jur")}
                  placeholder="Selecione uma ou mais áreas"
                  isClearable={false}
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={this.handleSelectArea}
                  options={areasjur}
                  indice={1012}
                />
              </div>
            </div>
          </div>

          <SelectInput
            label="Nível"
            name="vaga_nivel"
            options={dataNivel}
            value={this.getResposta("vaga_nivel")}
            onChange={this.handleChange}
            indice={1020}
          />

          <RadioInputs
            label="Vaga Sigilosa"
            name="vaga_sigilo"
            radios={dataSimNao}
            onChange={this.handleChange}
            checked={this.getResposta("vaga_sigilo")}
            value={this.getResposta("vaga_sigilo")}
            indice={1030}
          />

          <SelectInput
            label="Motivo da Abertura da Vaga"
            name="vaga_motivo_abertura"
            options={dataMotivoAbertura}
            obs={true}
            value={this.getResposta("vaga_motivo_abertura")}
            valueDescrOutros={this.getResposta(
              "vaga_motivo_abertura_descr_outros"
            )}
            valueObs={this.getResposta("vaga_motivo_abertura_obs")}
            onChange={this.handleChange}
            indice={1040}
          />

          <TextInput
            label="Data limite para início do Profissional"
            name="vaga_datalimite"
            value={this.getResposta("vaga_datalimite")}
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            onChange={this.handleChange}
            indice={1050}
          />

          <SelectInput
            label="Formato de trabalho"
            name="formato_trabalho"
            options={[
              { label: "Presencial" },
              { label: "Home Office" },
              { label: "Híbrido" }
            ]}
            value={this.getResposta("formato_trabalho")}
            onChange={this.handleChange}
            indice={1060}
          />
        </div>

        <PageLinks nextRoute="atividades1" prevRoute="respvaga" />
      </div>
    );
  }
}

// let SobreVaga = ({ respostas, updateValue }) => {

// };

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location)),
  updateValueArray: (name, value, label, indice, location) =>
    dispatch(updateValueArray(name, value, label, indice, location))
});

SobreVaga = connect(
  mapStateToProps,
  mapDispatchToProps
)(SobreVaga);

export default SobreVaga;
