import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { updateValue, updateValueArray } from "../../actions";

import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";
import PageLinks from "../../components/PageLinks";
import TextArea from "../../components/TextAreaInput";

const dataCheckboxesIndispensaveis = [
  { label: "Comprometimento", name: "comportamental_ind_comprometimento" },
  { label: "Concentração", name: "comportamental_ind_concentracao" },
  { label: "Iniciativa", name: "comportamental_ind_iniciativa" },
  { label: "Persistência", name: "comportamental_ind_persistencia" },
  { label: "Comunicação e Oratória", name: "comportamental_ind_comunic" },
  { label: "Organização", name: "comportamental_ind_organizacao" },
  { label: "Trabalho em equipe", name: "comportamental_ind_trabequipe" },
  { label: "Liderança", name: "comportamental_ind_lideranca" },
  { label: "Solução de problemas", name: "comportamental_ind_solprobl" },
  { label: "Perfil comercial", name: "comportamental_ind_perfilcomercial" }
];

const location = "atuacrescimento";

let Complementacao = ({ respostas, updateValue, updateValueArray }) => {
  const handleChange = e => {
    const { name, dataset, value } = e.target;
    // const value =
    //   e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const { label, indice } = dataset;
    if (e.target.type === "checkbox") {
      if (
        !e.target.checked ||
        getRespostasCheckbox("competencias_indispensaveis").length < 3
      ) {
        updateValueArray(name, value, label, indice, location);
      }
    } else {
      updateValue(name, value, label, indice, location);
    }
  };

  const getResposta = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  const getRespostasCheckbox = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return [];
  };

  return (
    <div>
      <div style={{ minHeight: "75vh" }}>
        <Title step={9} title="Informações Adicionais" />
        <TextArea
          label="Gostaria de acrescentar alguma informação ou apontamento importante sobre a vaga?"
          name="info_adicional"
          value={getResposta("info_adicional")}
          onChange={handleChange}
          rows={10}
          indice={2501}
        />
      </div>

      <PageLinks prevRoute="atuacrescimento" finish={true} />
    </div>
  );
};

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location)),
  updateValueArray: (name, value, label, indice, location) =>
    dispatch(updateValueArray(name, value, label, indice, location))
});

Complementacao = connect(
  mapStateToProps,
  mapDispatchToProps
)(Complementacao);

export default Complementacao;
