import { respostasReducer } from "./respostasReducer";
import { idiomasReducer } from "./idiomasReducer";
// import { OtherReducer } from "./otherReducer";
import { combineReducers } from "redux";
import { statusReducer } from "./statusReducer";
export const Reducers = combineReducers({
  respostas: respostasReducer,
  idiomas: idiomasReducer,
  status: statusReducer
});
