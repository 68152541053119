import React, { Component } from "react";

export default class SelectInput extends Component {
  state = {
    showOther: false
  };

  myRef = React.createRef();

  handleChangeSelect = e => {
    if (e.target.options[e.target.selectedIndex].dataset.other) {
      this.setState({ showOther: true });
    } else {
      this.setState({ showOther: false });
    }

    this.props.onChange(e);
  };

  componentDidMount() {
    if (
      this.myRef.current.options[this.myRef.current.selectedIndex].dataset.other
    ) {
      this.setState({ showOther: true });
    } else {
      this.setState({ showOther: false });
    }
    // console.log(this.myRef.current.value);
  }

  render() {
    const {
      label,
      name,
      options,
      value,
      placeholder,
      obs,
      info = null,
      valueDescrOutros,
      valueObs,
      placeHolderObs,
      indice
    } = this.props;

    return (
      <div className="uk-grid uk-grid-medium uk-flex-middle">
        <div className="uk-width-1-3@m uk-text-left">
          <div className="uk-margin-large-left" style={{ color: "#555555" }}>
            {label}
          </div>
        </div>
        <div className="uk-width-1-3@m">
          <div className="uk-width-1-1">
            <select
              ref={this.myRef}
              name={name}
              className="uk-select"
              onChange={this.handleChangeSelect}
              value={value}
              data-label={label}
              data-indice={indice}
            >
              {placeholder ? (
                <option value="" data-label={label} indice={indice}>
                  {placeholder}
                </option>
              ) : (
                <option data-label={label} indice={indice} />
              )}
              {options.map((o, i) => (
                <option
                  key={i}
                  value={o.label}
                  data-other={o.other && true}
                  data-label={label}
                  data-indice={indice}
                >
                  {o.label}
                </option>
              ))}
            </select>
          </div>
          {this.state.showOther && (
            <div className="uk-width-1-1">
              <input
                type="text"
                className="uk-input"
                name={`${name}_descr_outros`}
                onChange={this.props.onChange}
                value={valueDescrOutros}
                data-label={`${label} (Outros)`}
                data-indice={indice}
                placeholder="Descreva Outros"
                autoFocus
              />
            </div>
          )}
        </div>

        <div
          className="uk-width-1-3@m uk-text-left"
          style={{ fontSize: 12, paddingRight: 30 }}
        >
          {obs && (
            <div>
              <input
                type="text"
                className="uk-input"
                onChange={this.props.onChange}
                name={`${name}_obs`}
                value={valueObs}
                data-label={`${label} (Observações)`}
                data-indice={indice}
                placeholder={placeHolderObs ? placeHolderObs : "Observações"}
              />
            </div>
          )}
          {info && (
            <div className="uk-grid uk-grid-small uk-flex-middle">
              <div className="uk-width-1-6 uk-text-right">
                <span uk-icon="icon: info" />
              </div>
              <div className="uk-width-5-6" style={{ color: "#808080" }}>
                {info}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
