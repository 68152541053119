import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { updateValue, updateValueArray } from "../../actions";

import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";
import PageLinks from "../../components/PageLinks";
import TextArea from "../../components/TextAreaInput";
import SelectInput from "../../components/SelectInput";
import RadioInputs from "../../components/RadioInputs";
import dataSimNao from "../../data/dataSimNao";
import CheckBoxes from "../../components/CheckBoxes";

const dataRadiosCrescimento = [
  { label: "Estágio", value: 1 },
  { label: "Trainee", value: 2 },
  { label: "Júnior", value: 3 },
  { label: "Pleno", value: 4 },
  { label: "Sênior", value: 5 },
  { label: "Supervisor", value: 6 },
  { label: "Coordenação", value: 7 },
  { label: "Especialista", value: 8 },
  { label: "Gerência", value: 9 },
  { label: "Diretoria", value: 10 },
  { label: "Sócio", value: 11 }
];

const dataCheckboxesOutrasAtividades = [
  { label: "Aulas", name: "atuacao_fora_aulas" },
  {
    label: "Processos Particulares",
    name: "atuacao_fora_processosparticulares"
  },
  { label: "Consultorias", name: "atuacao_fora_consultoria" },
  {
    label: "Nenhuma atividade, exigimos contrato de exclusividade",
    name: "atuacao_fora_exclusividade"
  }
];

const location = "atuacrescimento";

let AtuaCrescimento = ({ respostas, updateValue, updateValueArray }) => {
  const handleChange = e => {
    console.log(e.target);
    const { name, dataset, value } = e.target;
    // const value =
    //   e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const { label, indice } = dataset;
    if (e.target.type === "checkbox") {
      updateValueArray(name, value, label, indice, location);
    } else {
      updateValue(name, value, label, indice, location);
    }
  };

  const getResposta = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  const getRespostasCheckbox = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return [];
  };

  return (
    <div>
      <div style={{ minHeight: "75vh" }}>
        <Title
          step={8}
          title="Atuação e Crescimento (Preenchimento Opcional)"
        />

        <TextArea
          name="vaga_descr_area"
          label="Descrição da área na qual o profissional vai atuar (nº de profissionais, posicionamento na equipe, desafios e características gerais da área)"
          value={getResposta("vaga_descr_area")}
          onChange={handleChange}
          indice={2101}
        />

        <CheckBoxes
          label="Com quais atividades o profissional poderá se envolver, além da atuação no escritório?"
          name="candidato_outras_atividades"
          other
          checkboxes={dataCheckboxesOutrasAtividades}
          valueCheckboxes={getRespostasCheckbox("candidato_outras_atividades")}
          value={getResposta("candidato_outras_atividades_outros")}
          onChange={handleChange}
          lockValue="Nenhuma atividade, exigimos contrato de exclusividade"
          indice={2102}
        />

        <Subtitle title="Sobre possibilidade de crescimento na equipe e no escritório/empresa" />

        <RadioInputs
          name="vaga_crescimento_max_area"
          radios={dataRadiosCrescimento}
          label="Considerando a posição em aberto, é possível chegar até qual cargo? (Assinale a alternativa correspondente ao cargo máximo que você considera ser possível alcançar na estrutura atual)"
          checked={getResposta("vaga_crescimento_max_area")}
          onChange={handleChange}
          indice={2103}
        />

        <TextArea
          label="O que precisa ocorrer para que o profissional possa chegar a esse cargo (tanto da parte do colaborador quanto da empresa/escritório)?"
          name="vaga_hist_socio_como"
          value={getResposta("vaga_hist_socio_como")}
          onChange={handleChange}
          indice={2104}
        />

        {getResposta("vaga_crescimento_max_area") === "Sócio" && (
          <div style={{ marginTop: 30 }}>
            <RadioInputs
              label="Existe histórico anterior de alguém que chegou à sociedade?"
              name="hist_cres_max"
              radios={dataSimNao}
              onChange={handleChange}
              checked={getResposta("hist_cres_max")}
              indice={2105}
            />
            {/* <SelectInput
              label=""
              name="hist_cres_max"
              options={dataSimNao}
              value={getResposta("hist_cres_max")}
              onChange={handleChange}
              indice={2105}
            /> */}

            {getResposta("hist_cres_max") === "Sim" && (
              <TextArea
                label="Pode nos contar um pouco sobre como aconteceu?"
                name="vaga_hist_socio"
                value={getResposta("vaga_hist_socio")}
                onChange={handleChange}
                indice={2106}
              />
            )}
          </div>
        )}
      </div>

      <PageLinks prevRoute="comportamental" nextRoute="complementacao" />
    </div>
  );
};

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location)),
  updateValueArray: (name, value, label, indice, location) =>
    dispatch(updateValueArray(name, value, label, indice, location))
});

AtuaCrescimento = connect(
  mapStateToProps,
  mapDispatchToProps
)(AtuaCrescimento);

export default AtuaCrescimento;
