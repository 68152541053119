import {
  UPDATE_VALUE,
  UPDATE_VALUE_ARRAY,
  CLEAR_VALUE
} from "../actions/actionTypes";
import _ from "lodash";

export const respostasReducer = (state = [], action) => {
  switch (action.type) {
    case UPDATE_VALUE:
      let respostas = _.filter(
        state,
        resp => resp.name !== action.payload.name
      );
      return [...respostas, action.payload];
    case CLEAR_VALUE:
      let respostas_clear_field = _.filter(
        state,
        resp => resp.name !== action.payload.name
      );
      return [...respostas_clear_field];
    case UPDATE_VALUE_ARRAY:
      // console.log(action.payload);
      let resp_item = _.find(state, i => i.name === action.payload.name);
      if (resp_item) {
        if (resp_item.value.includes(action.payload.value)) {
          if (resp_item.value.length > 1) {
            resp_item.value = _.filter(
              resp_item.value,
              i => i !== action.payload.value
            );
          } else {
            let respostas_array = _.filter(
              state,
              resp => resp.name !== action.payload.name
            );
            return [...respostas_array];
          }
        } else {
          resp_item.value.push(action.payload.value);
        }
      } else {
        action.payload.value = [action.payload.value];
        resp_item = action.payload;
      }

      let respostas_array = _.filter(
        state,
        resp => resp.name !== action.payload.name
      );
      return [...respostas_array, resp_item];

    // let respostas_array = _.filter(
    //   state,
    //   resp => resp.name !== action.payload.name
    // );
    default:
      return state;
  }
};
