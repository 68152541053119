import { SET_FINISH } from "../actions/actionTypes";

export const statusReducer = (state = { finished: false }, action) => {
  switch (action.type) {
    case SET_FINISH:
      return { finished: action.payload };
    default:
      return state;
  }
};
