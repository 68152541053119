import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { updateValue } from "../../actions";

import Subtitle from "../../components/Subtitle";
import Title from "../../components/Title";
import PageLinks from "../../components/PageLinks";
import TextInput from "../../components/TextInput";
import RadioInputs from "../../components/RadioInputs";

const dataNivelOffice = [
  { label: "Básico", value: 1 },
  { label: "Intermediário", value: 2 },
  { label: "Avançado", value: 3 }
];
const dataConhecimentoSisJur = [
  { label: "Básico", value: 1 },
  { label: "Intermediário", value: 2 },
  { label: "Avançado", value: 3 },
  { label: "Não necessário mas é um diferencial", value: 4 },
  { label: "Não necessário", value: 5 }
];

const location = "reqvaga3";

let ReqVaga3 = ({ respostas, updateValue }) => {
  const handleChange = e => {
    console.log(e.target);
    const { name, dataset, value } = e.target;
    // const value =
    //   e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const { label, indice } = dataset;
    updateValue(name, value, label, indice, location);
  };

  const getResposta = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  return (
    <div>
      <div style={{ minHeight: "75vh" }}>
        <Title step={4} title="Requisitos para a Vaga" part={2} />

        <Subtitle title="Informática" />

        <RadioInputs
          label="Microsoft Office"
          labelPergunta="Informática - Microsoft Office"
          name="vaga_req_office"
          radios={dataNivelOffice}
          value={getResposta("vaga_req_office_outros")}
          onChange={handleChange}
          checked={getResposta("vaga_req_office")}
          other
          obs
          indice={1501}
        />

        <Subtitle title="Sistemas Jurídicos" />

        <TextInput
          label="Sistemas jurídicos utilizados"
          name="sistema_juridico"
          onChange={handleChange}
          value={getResposta("sistema_juridico")}
          indice={1502}
        />

        <RadioInputs
          label="Nível de conhecimento do Profissional nos sistemas acima listados"
          name="vaga_req_sisjur_nivel"
          radios={dataConhecimentoSisJur}
          onChange={handleChange}
          checked={getResposta("vaga_req_sisjur_nivel")}
          indice={1503}
        />
      </div>

      <PageLinks nextRoute="perfilpessoal" prevRoute="reqvaga2" />
    </div>
  );
};

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location))
});

ReqVaga3 = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReqVaga3);

export default ReqVaga3;
