import React, { Component } from "react";
import { connect } from "react-redux";
import { addLanguage, updateValue } from "../../actions";
import _ from "lodash";
import axios from "axios";

import TextInput from "../../components/TextInput";
import Subtitle from "../../components/Subtitle";
import Title from "../../components/Title";
import PageLinks from "../../components/PageLinks";

class RespVaga extends Component {
  state = {
    location: "respvaga",
    isLoading: true
  };

  handleChange = e => {
    const { name, value, dataset } = e.target;
    const { location } = this.state;
    const { label, indice } = dataset;
    this.props.updateValue(name, value, label, indice, location);
  };

  getResposta = name => {
    const resposta = _.find(this.props.respostas, r => r.name == name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  componentDidMount = () => {
    // busca na API as respostas já preenchidas
    // var data = {
    //   hash: this.props.match.params.hash
    // };

    // axios
      // .post(`https://sistema.perroniconsultoria.com/api/getrespostas`, data)
      // .post(`/api/getrespostas`, data)
      // .then(resp => {
      //   console.log(resp.data);
      //   resp.data.respostas.map(r => {
      //     if (r.name === "idioma") {
      //       this.props.addLanguage({
      //         idioma: r.label.split('Idioma ')[1],
      //         nivel: r.value.split(' (')[0],
      //         diferencial: (r.value && r.value.split(' (')[1] && r.value.split(' (')[1].split(')')[0] == 'Diferencial') ? true : false
      //       });
      //     } else {
      //       if (r.name === "areas_jur") {
      //         var values = r.value.split(', ');
      //         this.props.updateValue(r.name, values, r.label, r.indice, r.location);
      //       } else {
      //         this.props.updateValue(r.name, r.value, r.label, r.indice, r.location);
      //       }
      //     }
      //   })
        // if (resp.data.status === "success") {
        //   this.setState({ isLoading: false, finished: true });
        // }
      // });
  };

  render() {
    return (
      <div>
        <div style={{ minHeight: "75vh" }}>
          <Title step="1" title="Responsável pela vaga" />
          <TextInput
            label="Nome do Responsável"
            info="Profissional do escritório/empresa que será responsável pelo contato com a consultoria"
            name="responsavel_nome"
            indice={1}
            value={this.getResposta("responsavel_nome")}
            onChange={this.handleChange}
          />
          <TextInput
            label="Setor/área em que atua"
            name="responsavel_setor"
            value={this.getResposta("responsavel_setor")}
            indice={2}
            onChange={this.handleChange}
          />

          <Subtitle title="Contato" />

          <TextInput
            label="E-Mail"
            name="responsavel_email"
            value={this.getResposta("responsavel_email")}
            onChange={this.handleChange}
            indice={3}
          />
          <TextInput
            label="Telefone Celular"
            name="responsavel_telcel"
            value={this.getResposta("responsavel_telcel")}
            onChange={this.handleChange}
            indice={4}
            mask={[
              "(",
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              "-",
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]}
          />
          <TextInput
            label="Telefone Fixo"
            name="responsavel_telfixo"
            value={this.getResposta("responsavel_telfixo")}
            onChange={this.handleChange}
            indice={5}
            mask={[
              "(",
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              " ",
              "-",
              " ",
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ]}
          />
        </div>

        <PageLinks nextRoute="sobrevaga" match={this.props.match} />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location)),
    addLanguage: language => dispatch(addLanguage(language))
});

RespVaga = connect(
  mapStateToProps,
  mapDispatchToProps
)(RespVaga);
// export default connect(mapStateToProps)(RespVaga);
export default RespVaga;
