import React from "react";
import { Link } from "react-router-dom";

const LinkNext = ({ to }) => (
  <div
    style={{ marginTop: 40, paddingLeft: 100 }}
    className="uk-text-left uk-width-1-3"
  >
    <Link
    onClick={window.scrollTo(0,0)}
      to={to}
      className="uk-button uk-button-primary uk-width-4-5 uk-text-bold uk-text-left"
    >
      <span
        className="uk-margin-large-right uk-margin-small-left"
        style={{ fontWeight: "400" }}
        uk-icon="icon: arrow-left; ratio: 1.8"
      />
      ANTERIOR
    </Link>
  </div>
);

export default LinkNext;
