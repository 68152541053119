import React from "react";
import RadioInput from "./RadioInput";

const RadioInputs = ({
  label,
  labelTag,
  radios,
  name,
  obs = false,
  other = false,
  onChange,
  checked,
  value,
  indice,
  labelHidden = false,
  placeholderOther
}) => (
  <div className="uk-grid uk-grid-medium uk-flex-top">
    <div className="uk-width-1-3@m uk-text-left">
      {!labelHidden && (
        <div className="uk-margin-large-left" style={{ color: "#555555" }}>
          {label} {labelTag && <span class="uk-label">{labelTag}</span>}
        </div>
      )}
    </div>
    <div className="uk-width-1-3@m">
      {radios.map((c, i) => (
        <RadioInput
          key={i}
          label={c.label}
          labelPergunta={label}
          name={name}
          value={c.value}
          onChange={onChange}
          checked={checked === c.label ? true : false}
          indice={indice}
        />
      ))}
    </div>
    {other && (
      <div className="uk-width-1-3@m uk-text-left" style={{ fontSize: 12 }}>
        <div className="uk-grid uk-grid-small uk-flex-middle">
          <div className="uk-width-1-1" style={{ paddingRight: 20 }}>
            <input
              type="text"
              name={`${name}_outros`}
              onChange={onChange}
              value={value}
              className="uk-input"
              placeholder={
                obs
                  ? "Observações"
                  : placeholderOther
                  ? placeholderOther
                  : "Outros"
              }
              data-label={`${label} (${
                obs
                  ? "Observações"
                  : placeholderOther
                  ? placeholderOther
                  : "Outros"
              })`}
              data-indice={indice}
            />
          </div>
        </div>
      </div>
    )}
  </div>
);

export default RadioInputs;
