import React, {useEffect} from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { updateValue, updateValueArray } from "../../actions";

import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";
import PageLinks from "../../components/PageLinks";
import TextArea from "../../components/TextAreaInput";

const dataCheckboxesIndispensaveis = [
  { label: "Comprometimento", name: "comportamental_ind_comprometimento" },
  { label: "Concentração", name: "comportamental_ind_concentracao" },
  { label: "Iniciativa", name: "comportamental_ind_iniciativa" },
  { label: "Persistência", name: "comportamental_ind_persistencia" },
  { label: "Comunicação e Oratória", name: "comportamental_ind_comunic" },
  { label: "Organização", name: "comportamental_ind_organizacao" },
  { label: "Trabalho em equipe", name: "comportamental_ind_trabequipe" },
  { label: "Liderança", name: "comportamental_ind_lideranca" },
  { label: "Solução de problemas", name: "comportamental_ind_solprobl" },
  { label: "Perfil comercial", name: "comportamental_ind_perfilcomercial" },
  { label: "Criatividade", name: "comportamental_ind_criatividade" },
  { label: "Proatividade", name: "comportamental_ind_proatividade" },
  { label: "Flexibilidade", name: "comportamental_ind_flexibilidade" },
  { label: "Negociação / Persuasão", name: "comportamental_ind_negociacao_persuasao" },
  { label: "Foco em resultados", name: "comportamental_ind_foco_em_resultados" },
  { label: "Relacionamento Interpessoal", name: "comportamental_ind_relacionamento_interpessoal" },
  { label: "Habilidade para ouvir", name: "comportamental_ind_habilidade_para_ouvir" },
  { label: "Motivação", name: "comportamental_ind_motivacao" },
  { label: "Tomada de decisão", name: "comportamental_ind_tomada_de_decisao" },
  { label: "Disciplina", name: "comportamental_ind_disciplina" },
  { label: "Lidar com pressão", name: "comportamental_ind_lidar_com_pressao" },
  { label: "Inteligência Emocional", name: "comportamental_ind_inteligencia_emocional" },
  { label: "Relacionamento com o cliente", name: "comportamental_ind_relacionamento_com_o_cliente" },
  { label: "Resiliência", name: "comportamental_ind_resiliencia" }
];

const location = "comportamental";

let Comportamental = ({ respostas, updateValue, updateValueArray }) => {
  const handleChange = e => {
    const { name, dataset, value } = e.target;
    // const value =
    //   e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const { label, indice } = dataset;
    if (e.target.type === "checkbox") {
      if (
        !e.target.checked ||
        getRespostasCheckbox("competencias_indispensaveis")
      ) {
        updateValueArray(name, value, label, indice, location);
      }
    } else {
      updateValue(name, value, label, indice, location);
    }
    enableNextRoute();
  };
  
  const enableNextRoute = () => {
    const nextPageAnchor = document.querySelector('a.uk-text-right')
    if(getRespostasCheckbox("competencias_indispensaveis").length < 3){
      nextPageAnchor.setAttribute('style','background-color:gray;pointer-events:none')
      nextPageAnchor.setAttribute('disabled', '')
      return 
    }
    nextPageAnchor.removeAttribute('style')
    nextPageAnchor.removeAttribute('disabled')
    return
  }

  useEffect(() =>{enableNextRoute()},[])

  const getResposta = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  const getRespostasCheckbox = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return [];
  };

  return (
    <div>
      <div style={{ minHeight: "75vh" }}>
        <Title step={7} title="Avaliação Comportamental" />

        <Subtitle title="Assinale pelo menos 3 competências comportamentais que você considera mais importantes para o profissional que ocupará esse cargo" />
        <div
          className="uk-grid uk-grid-small"
          style={{ marginLeft: 50, marginRight: 50 }}
        >
          {dataCheckboxesIndispensaveis.map((c1, i1) => (
            <label
              key={i1}
              className="uk-width-1-4@m uk-width-1-2@s uk-text-left uk-flex-middle"
              style={{ marginTop: 8, marginBottom: 8, display: "block" }}
            >
              <input
                type="checkbox"
                className="uk-checkbox uk-margin-small-right"
                name="competencias_indispensaveis"
                data-label="Competencias Indispensáveis"
                value={c1.label}
                checked={getRespostasCheckbox(
                  "competencias_indispensaveis"
                ).includes(c1.label)}
                onChange={handleChange}
                data-indice="2001"
              />
              {c1.label}
            </label>
          ))}
        </div>

        <TextArea
          label="Gostaria de indicar mais alguma competência comportamental?"
          name="indicacao_competencia_comportamental"
          value={getResposta("indicacao_competencia_comportamental")}
          onChange={handleChange}
          indice={2002}
        />
      </div>

      <PageLinks prevRoute="remuneracao" nextRoute="atuacrescimento"/>
    </div>
  );
};

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location)),
  updateValueArray: (name, value, label, indice, location) =>
    dispatch(updateValueArray(name, value, label, indice, location))
});

Comportamental = connect(
  mapStateToProps,
  mapDispatchToProps
)(Comportamental);

export default Comportamental;
