import React from "react";

const Title = ({ title, step, part = null }) => (
  <div style={{ marginBottom: 50 }}>
    <div className="uk-grid uk-flex-middle">
      <div className="uk-width-1-5" style={{ fontSize: 30, color: "#C0C0C0" }}>
        {step && `PASSO ${step}`}
      </div>
      <div
        className="uk-text-center uk-width-3-5"
        style={{ fontSize: 40, color: "#2A474E" }}
      >
        <span>{title}</span>
        {step && (
          <div className="uk-margin-left uk-margin-right uk-margin-top">
            <progress className="uk-progress" value={step} max="9" />
          </div>
        )}
      </div>
      {part && (
        <div className="uk-width-1-5" style={{ color: "#C0C0C0" }}>
          PARTE {part}
        </div>
      )}
    </div>
  </div>
);

export default Title;
