import React from "react";
import { Link } from "react-router-dom";

const Home = ({ match }) => (
  <div>
    <p style={{ marginTop: 20, fontSize: 18, fontWeight: "bold" }}>
      SOBRE O PROCESSO DE ABERTURA DE VAGA
    </p>
    <div
      style={{
        marginTop: 35,
        marginBottom: 60,
        marginLeft: "20%",
        marginRight: "20%",
        textAlign: "justify"
      }}
    >
      <p style={{ fontWeight: "bold" }}>Caro Cliente,</p>
      <p>
        O preenchimento desse formulário é de suma importância para a
        assertividade da seleção.
      </p>
      <p style={{ marginTop: 30 }}>
        A descrição e o alinhamento do perfil constituem etapa primordial do
        processo seletivo.
      </p>
      <p style={{ marginTop: 30 }}>
        Ao completar os dados solicitados nas próximas páginas, pense em suas
        reais necessidades e, ao mesmo tempo, nas condições oferecidas para o
        cargo. Quanto mais informações e detalhes tivermos, mais precisos
        conseguiremos ser na busca do profissional.
      </p>
      <p>
        Estamos disponíveis para quaisquer dúvidas e apontamentos necessários.
      </p>
      <p>Cordialmente,</p>
      <p style={{ fontWeight: "bold" }}>Perroni Consultoria</p>
    </div>
    <Link
      to={`${match.url}/respvaga`}
      className="uk-button uk-button-primary uk-button-large"
    >
      PREENCHER REQUISITOS DA VAGA
    </Link>
  </div>
);

export default Home;
