import React, { Component } from "react";
import { connect } from "react-redux";
import { addLanguage, updateValue } from "../actions";
import _ from "lodash";
import axios from "axios";

class LoadRespostas extends Component {
  state = {
    isLoading: true
  }
  componentDidMount = () => {
    // busca na API as respostas já preenchidas
    var data = {
      hash: this.props.match.params.hash
    };

    axios
      .post(`https://sistema.perroniconsultoria.com/api/getrespostas`, data)
      // .post(`/api/getrespostas`, data)
      .then(resp => {
        console.log(resp.data);
        resp.data.respostas.map(r => {
          if (r.name === "idioma") {
            this.props.addLanguage({
              idioma: r.label.split('Idioma ')[1],
              nivel: r.value.split(' (')[0],
              diferencial: (r.value && r.value.split(' (')[1] && r.value.split(' (')[1].split(')')[0] == 'Diferencial') ? true : false
            });
          } else {
            if (r.name === "areas_jur") {
              var values = r.value.split(', ');
              this.props.updateValue(r.name, values, r.label, r.indice, r.location);
            } else {
              this.props.updateValue(r.name, r.value, r.label, r.indice, r.location);
            }
          }
          this.setState({ isLoading: false });
        })
      }).finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <div>Carregando respostas...</div>
      );
    }

    return null;
    
  }
}

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location)),
    addLanguage: language => dispatch(addLanguage(language))
});

LoadRespostas = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadRespostas);
// export default connect(mapStateToProps)(RespVaga);
export default LoadRespostas;
