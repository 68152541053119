import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "uikit/dist/css/uikit.min.css";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import App from "./routes";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { Store } from "./store";

// loads the Icon plugin
UIkit.use(Icons);

ReactDOM.render(
  <Provider store={Store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
