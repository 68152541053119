import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { addLanguage, removeLanguage, updateValue } from "../../actions";

import Subtitle from "../../components/Subtitle";
import Title from "../../components/Title";
import PageLinks from "../../components/PageLinks";
import RadioInputs from "../../components/RadioInputs";
import dataSimNao from "../../data/dataSimNao";

const optionsLanguageLevel = [
  { label: "Básico", value: 1 },
  { label: "Intermediário", value: 2 },
  { label: "Avançado", value: 3 },
  { label: "Fluente", value: 4 }
];

const location = "reqvaga2";

class ReqVaga2 extends Component {
  state = {
    idioma: "",
    nivel: "",
    diferencial: false
  };

  handleChange = e => {
    const { name, dataset, value } = e.target;
    // const value =
    //   e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const { label, indice } = dataset;
    updateValue(name, value, label, indice, location);
  };

  getResposta = name => {
    const resposta = _.find(this.props.respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  getLabel = (options, value) => {
    const option = _.find(options, o => o.value == value);

    return option.label;
  };

  resetForm = () => {
    this.setState({
      idioma: "",
      nivel: "",
      diferencial: false
    });
  };

  handleClickAdd = () => {
    // TODO: VERIFICAR SE LINGUA JA EXISTE

    this.props.addLanguage(this.state);
    this.resetForm();
  };

  handleClickRemove = idioma => {
    this.props.removeLanguage(idioma);
    this.resetForm();
  };

  render() {
    const { idioma, nivel, diferencial } = this.state;
    return (
      <div>
        <div style={{ minHeight: "65vh" }}>
          <Title step="4" title="Requisitos para a Vaga" part={1} />

          <Subtitle title="Idiomas" />

          <div className="uk-grid uk-grid-small uk-margin-large-left uk-flex-middle">
            <div className="uk-width-1-4@m uk-text-left ">
              <div className="">Idioma</div>
              <select
                name="idioma"
                className="uk-select uk-form-width-medium"
                onChange={e => this.setState({ idioma: e.target.value })}
                value={idioma}
              >
                <option value="" />
                {[
                  { label: "Inglês", value: "Inglês" },
                  { label: "Espanhol", value: "Espanhol" },
                  { label: "Francês", value: "Francês" },
                  { label: "Alemão", value: "Alemão" },
                  { label: "Italiano", value: "Italiano" },
                  { label: "Mandarim", value: "Mandarim" }
                ].map((l, i) => (
                  <option key={i} value={l.value}>
                    {l.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="uk-width-1-4@m uk-text-left ">
              <div>Nível</div>
              <select
                name=""
                id=""
                className="uk-select uk-form-width-medium"
                onChange={e => this.setState({ nivel: e.target.value })}
                value={nivel}
              >
                <option value="" />
                {optionsLanguageLevel.map((l, i) => (
                  <option key={i} value={l.label}>
                    {l.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="uk-width-expand uk-text-left">
              <div className="uk-grid uk-grid-small">
                <div className="uk-width-2-3">
                  <label>
                    <input
                      type="checkbox"
                      checked={diferencial}
                      onChange={e =>
                        this.setState({
                          diferencial: e.target.checked ? true : false
                        })
                      }
                    />{" "}
                    Não é necessário, mas será considerado um diferencial
                  </label>
                </div>
                <div className="uk-width-1-3">
                  <button
                    className="uk-button uk-button-primary uk-button-small"
                    onClick={this.handleClickAdd}
                  >
                    Adicionar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div style={{ margin: 60 }}>
            <table className="uk-table uk-table-divider uk-text-left">
              <thead>
                <tr>
                  <th>Idioma</th>
                  <th>Nível</th>
                  <th>Obs</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.props.idiomas.map((idioma, i) => (
                  <tr key={i}>
                    <td>{idioma.idioma}</td>
                    <td>{idioma.nivel}</td>
                    <td>
                      {idioma.diferencial &&
                        "Não é necessário mas considero um diferencial"}
                    </td>
                    <td>
                      <button
                        className="uk-icon-button uk-button-danger uk-margin-small-right"
                        uk-icon="close"
                        onClick={() => this.handleClickRemove(idioma)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* <RadioInputs
            labelHidden={true}
            label="Não é necessário"
            name="lingua_adicional"
            radios={[{ label: "Não é necessário idioma adicional" }]}
            onChange={this.handleChange}
            checked={this.getResposta("lingua_adicional")}
            value={this.getResposta("lingua_adicional")}
            indice={9988}
          /> */}
        </div>

        <PageLinks
          nextRoute="reqvaga3"
          nextLabel={this.props.idiomas.length > 0 ? false : "Não é necessário"}
          prevRoute="atividades2"
        />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  idiomas: store.idiomas,
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location)),
  addLanguage: language => dispatch(addLanguage(language)),
  removeLanguage: language => dispatch(removeLanguage(language))
});

ReqVaga2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReqVaga2);

export default ReqVaga2;
