import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { updateValue, updateValueArray } from "../../actions";

import TextInput from "../../components/TextInput";
import RadioInputs from "../../components/RadioInputs";
import TextAreaInput from "../../components/TextAreaInput";
import Subtitle from "../../components/Subtitle";
import Title from "../../components/Title";
import PageLinks from "../../components/PageLinks";
import CheckBoxes from "../../components/CheckBoxes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const dataCheckboxesEsferaAtuacao = [
  {
    label: "Consultivo",
    name: "esfera_atuacao_consultivo",
    value: "Consultivo"
  },
  {
    label: "Contencioso",
    name: "esfera_atuacao_contencioso",
    value: "Contencioso"
  }
];
const dataCheckboxesContencioso = [
  { label: "Administrativo", name: "atuacao_contencioso_adm", value: 0 },
  { label: "Judicial", name: "atuacao_contencioso_judicial", value: 0 }
];
const dataCheckboxesConsultivo = [
  {
    label: "Opiniões Legais",
    name: "atuacao_consultivo_opinlegais",
    value: "Opiniões Legais"
  },
  {
    label: "Pareceres",
    name: "atuacao_consultivo_pareceres",
    value: "Opiniões Legais"
  },
  {
    label: "Esclarecimento de Dúvidas Cotidianas",
    name: "atuacao_consultivo_esclduvidas",
    value: false
  }
];

const dataCheckboxesContratosAnal = [
  {
    label: "Compra e Venda",
    name: "tipo_contrato_compra_venda",
    value: "Compra e Venda"
  },
  {
    label: "Contratos de Infraestrutura",
    name: "tipo_contrato_anal_infra",
    value: "Contratos de Infraestrutura"
  },
  {
    label: "Locação",
    name: "tipo_contrato_anal_locacao",
    value: "Locação"
  },
  {
    label: "Contratos Coligados",
    name: "tipo_contrato_anal_coligados",
    value: "Contratos Coligados"
  },
  {
    label: "Fornecedores",
    name: "tipo_contrato_anal_fornecedores",
    value: "Fornecedores"
  },
  {
    label: "Prestação de Serviços",
    name: "tipo_contrato_anal_servicos",
    value: "Prestação de Serviços"
  }
];

const dataCheckboxesContratosElab = [
  {
    label: "Compra e Venda",
    name: "tipo_contrato_compra_venda",
    value: "Compra e Venda"
  },
  {
    label: "Contratos de Infraestrutura",
    name: "tipo_contrato_infra",
    value: "Contratos de Infraestrutura"
  },
  {
    label: "Locação",
    name: "tipo_contrato_locacao",
    value: "Locação"
  },
  {
    label: "Contratos Coligados",
    name: "tipo_contrato_coligados",
    value: "Contratos Coligados"
  },
  {
    label: "Fornecedores",
    name: "tipo_contrato_fornecedores",
    value: "Fornecedores"
  },
  {
    label: "Prestação de Serviços",
    name: "tipo_contrato_servicos",
    value: "Prestação de Serviços"
  }
];

const dataCheckboxesMediaProcessos = [
  {
    label: "Até 250 processos",
    name: "atuacao_processos_ate250",
    value: "Até 250 processos"
  },
  {
    label: "250 a 600 processos",
    name: "atuacao_contencioso_250a600",
    value: "250 a 600 processos"
  },
  {
    label: "acima de 600 processos",
    name: "atuacao_contencioso_acima600",
    value: "acima de 600 processos"
  }
];

const location = "atividades1";

let Atividades1 = ({ respostas, updateValue, updateValueArray }) => {
  const handleChange = e => {
    // console.log(e.target);
    const { name, dataset, value } = e.target;
    // const value =
    //   e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const { label, indice } = dataset;
    if (e.target.type === "checkbox") {
      updateValueArray(name, value, label, indice, location);
    } else {
      updateValue(name, value, label, indice, location);
    }
  };

  const getResposta = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  const getRespostasCheckbox = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return [];
  };

  const getDataCheckBoxConsultivo = () => {
    let dataReturn = dataCheckboxesConsultivo;
    let civil = [
      {
        label: "Análise de Contratos",
        name: "",
        value: 0
      },
      {
        label: "Elaboração de Contratos",
        name: "",
        value: 0
      }
    ];

    let contratos = [
      {
        label: "Análise de Contratos",
        name: "",
        value: 0
      },
      {
        label: "Elaboração de Contratos",
        name: "",
        value: 0
      },
      {
        label: "Atos societários",
        name: "",
        value: 0
      },
      {
        label: "Operações Societárias",
        name: "",
        value: 0
      }
    ];

    let tributario = [
      {
        label: "Planejamento Tributário",
        name: "",
        value: 0
      },
      {
        label: "Diretos",
        name: "",
        value: 0
      },
      {
        label: "Indiretos",
        name: "",
        value: 0
      }
    ];

    if (
      getResposta("areas_jur").includes("Tributário") ||
      getResposta("areas_jur").includes(
        "TAX (Consultoria Tributaria Fiscal)"
      ) ||
      getResposta("areas_jur").includes("Contratos") ||
      getResposta("areas_jur").includes("Societário") ||
      getResposta("areas_jur").includes("Cível")
    ) {
      // SE É TRIB
      if (
        getResposta("areas_jur").includes("Tributário") ||
        getResposta("areas_jur").includes("TAX (Consultoria Tributaria Fiscal)")
      ) {
        dataReturn = dataReturn.concat(tributario);
      }
      // SE É SOCIETARIO
      if (
        getResposta("areas_jur").includes("Contratos") ||
        getResposta("areas_jur").includes("Societário")
      ) {
        dataReturn = dataReturn.concat(contratos);
      }

      // SE É Cível
      if (getResposta("areas_jur").includes("Cível")) {
        dataReturn = dataReturn.concat(civil);
      }

      // return dataReturn;
    }

    return dataReturn;
  };

  return (
    <div>
      <div style={{ minHeight: "63vh" }}>
        <Title step="3" part="1" title="Atividades do Profissional" />

        <TextAreaInput
          label="Atividades a serem desenvolvidas"
          name="vaga_descr_atividades_desenvolvidas"
          value={getResposta("vaga_descr_atividades_desenvolvidas")}
          onChange={handleChange}
          indice={1101}
        />

        <Subtitle title="Esfera de Atuação" />

        <CheckBoxes
          label="Esfera de Atuação"
          name="esfera_atuacao"
          // checked={getResposta("esfera_atuacao")}
          valueCheckboxes={getRespostasCheckbox("esfera_atuacao")}
          checkboxes={dataCheckboxesEsferaAtuacao}
          onChange={handleChange}
          indice={1110}
        />

        {getResposta("esfera_atuacao").includes("Consultivo") && (
          <div style={{ marginTop: 25, marginBottom: 25 }}>
            <CheckBoxes
              label="Atuação (Consultivo)"
              name="atuacao_consultivo"
              other
              otherPlaceHolder="Descreva com o máximo de detalhes a atuação no consultivo"
              checkboxes={getDataCheckBoxConsultivo()}
              valueCheckboxes={getRespostasCheckbox("atuacao_consultivo")}
              value={getResposta("atuacao_consultivo_outros")}
              onChange={handleChange}
              indice={1111}
            />

            {getResposta("atuacao_consultivo").includes("Diretos") && (
              <TextInput
                label="Diretos - Quais Tributos?"
                name="trib_diretos"
                value={getResposta("vaga_atuacao_consultivo_atos_societarios")}
                onChange={handleChange}
                indice={1112}
              />
            )}
            {getResposta("atuacao_consultivo").includes("Indiretos") && (
              <TextInput
                label="Indiretos - Quais Tributos?"
                name="trib_indiretos"
                value={getResposta("vaga_atuacao_consultivo_atos_societarios")}
                onChange={handleChange}
                indice={1113}
              />
            )}

            {getResposta("atuacao_consultivo").includes(
              "Análise de Contratos"
            ) && (
              <CheckBoxes
                label="Tipos de Contratos (Análise)"
                other
                name="tipo_contrato_analise"
                value={getResposta("tipo_contrato_analise_outros")}
                labelTag="Consultivo"
                checkboxes={dataCheckboxesContratosAnal}
                valueCheckboxes={getRespostasCheckbox("tipo_contrato_analise")}
                onChange={handleChange}
                indice={1114}
              />
            )}

            {getResposta("atuacao_consultivo").includes(
              "Elaboração de Contratos"
            ) && (
              <CheckBoxes
                label="Tipos de Contratos (Elaboração)"
                other
                name="tipo_contrato_elaboracao"
                value={getResposta("tipo_contrato_elaboracao_outros")}
                labelTag="Consultivo"
                checkboxes={dataCheckboxesContratosElab}
                valueCheckboxes={getRespostasCheckbox(
                  "tipo_contrato_elaboracao"
                )}
                onChange={handleChange}
                indice={1115}
              />
            )}
            {getResposta("atuacao_consultivo_atos_societarios") && (
              <TextInput
                placeholder="Descreva quais atos"
                label="Atos societários"
                name="vaga_atuacao_consultivo_atos_societarios"
                value={getResposta("vaga_atuacao_consultivo_atos_societarios")}
                onChange={handleChange}
                indice={1116}
              />
            )}

            {getResposta("atuacao_consultivo_oper_societarias") && (
              <TextInput
                placeholder="Descreva quais operações"
                label="Operações societárias"
                name="vaga_atuacao_consultivo_oper_societarios"
                value={getResposta("vaga_atuacao_consultivo_oper_societarios")}
                onChange={handleChange}
                indice={1117}
              />
            )}
          </div>
        )}

        {getResposta("esfera_atuacao").includes("Contencioso") && (
          <div>
            <CheckBoxes
              label="Atuação (Contencioso)"
              name="atuacao_contencioso"
              other
              obs
              valueCheckboxes={getRespostasCheckbox("atuacao_contencioso")}
              value={getResposta("atuacao_contencioso_outros")}
              checkboxes={dataCheckboxesContencioso}
              onChange={handleChange}
              indice={1118}
            />

            <RadioInputs
              label="Média de Processos"
              name="atuacao_media_processos"
              other
              checked={getResposta("atuacao_media_processos")}
              value={getResposta("atuacao_media_processos_outros")}
              radios={dataCheckboxesMediaProcessos}
              onChange={handleChange}
              indice={1119}
            />
          </div>
        )}

        {getResposta("esfera_atuacao").includes("Outros") && (
          <TextInput
            placeholder="Descreva"
            label="Outros (Tarefas administrativas, prospecção de clientes, viagens, etc)"
            name="vaga_atuacao_outras_tarefas"
            value={getResposta("vaga_atuacao_outras_tarefas")}
            onChange={handleChange}
            indice={1120}
          />
        )}

        <div className="uk-grid uk-grid-medium uk-flex-top">
          <div className="uk-width-1-3@m uk-text-left">
            <div className="uk-margin-large-left">
              Qual % aproximado de tempo que o profissional geralmente atuará
              nessas áreas?
            </div>
          </div>
          <div className="uk-width-2-3@m uk-text-left">
            <label style={{ display: "block", marginBottom: 10 }}>
              <div className="uk-inline" style={{ marginRight: 5 }}>
                <FontAwesomeIcon
                  className="uk-form-icon uk-form-icon-flip"
                  style={{ marginRight: 16, marginTop: 12 }}
                  icon="percentage"
                />
                {/* <span className="uk-form-icon" uk-icon="icon: star" /> */}
                <input
                  className="uk-input"
                  style={{ width: 80 }}
                  maxLength={3}
                  type="text"
                  name="atuacao_percent_consultivo"
                  value={getResposta("atuacao_percent_consultivo")}
                  onChange={handleChange}
                  data-indice="1121"
                  data-label="% de Atuação em Consultivo"
                />
              </div>
              Consultivo
            </label>
            <label style={{ display: "block", marginBottom: 10 }}>
              <div className="uk-inline" style={{ marginRight: 5 }}>
                <FontAwesomeIcon
                  className="uk-form-icon uk-form-icon-flip"
                  style={{ marginRight: 16, marginTop: 12 }}
                  icon="percentage"
                />
                {/* <span className="uk-form-icon" uk-icon="icon: star" /> */}
                <input
                  className="uk-input"
                  style={{ width: 80 }}
                  maxLength={3}
                  type="text"
                  name="atuacao_percent_contencioso"
                  value={getResposta("atuacao_percent_contencioso")}
                  onChange={handleChange}
                  data-indice="1122"
                  data-label="% de Atuação em Contencioso"
                />
              </div>
              Contencioso
            </label>
            <label style={{ display: "block", marginBottom: 10 }}>
              <div className="uk-inline" style={{ marginRight: 5 }}>
                <FontAwesomeIcon
                  className="uk-form-icon uk-form-icon-flip"
                  style={{ marginRight: 16, marginTop: 12 }}
                  icon="percentage"
                />
                {/* <span className="uk-form-icon" uk-icon="icon: star" /> */}
                <input
                  className="uk-input"
                  style={{ width: 80 }}
                  maxLength={3}
                  type="text"
                  name="atuacao_percent_outros"
                  value={getResposta("atuacao_percent_outros")}
                  onChange={handleChange}
                  data-indice="1123"
                  data-label="% de Atuação em Outros"
                />
              </div>
              Outros (Tarefas administrativas, prospecção de clientes, viagens,
              etc)
            </label>
          </div>
        </div>
      </div>

      <PageLinks nextRoute="atividades2" prevRoute="sobrevaga" />
    </div>
  );
};

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location)),
  updateValueArray: (name, value, label, indice, location) =>
    dispatch(updateValueArray(name, value, label, indice, location))
});

Atividades1 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Atividades1);

export default Atividades1;
