import React from "react";
import { connect } from "react-redux";

import LinkNext from "./LinkNext";
import LinkPrev from "./LinkPrev";
import LinkConcluir from "./LinkConcluir";

let PageLinks = ({
  nextRoute = null,
  prevRoute = null,
  finish = null,
  nextLabel = false,
  status,
  match
}) => (
  <div className="uk-grid uk-margin-bottom">
    {prevRoute ? (
      <LinkPrev to={`${prevRoute}`} />
    ) : (
      <div className="uk-width-1-3" />
    )}
    {nextRoute && (
      <div className="uk-width-1-3">
        {status.finished && (
          <div
            style={{ marginTop: 40, paddingRight: 60 }}
            className="uk-width-1-1 uk-text-right"
          >
            <LinkConcluir />
          </div>
        )}
      </div>
    )}

    {nextRoute ? (
      <LinkNext label={nextLabel ? nextLabel : false} to={`${nextRoute}`} />
    ) : (
      <div
        style={{ marginTop: 40, paddingRight: 60 }}
        className="uk-width-1-3 uk-text-right"
      >
        <LinkConcluir />
      </div>
    )}
    {/* {finish ? <LinkConcluir /> : <div className="uk-width-1-2" />} */}
  </div>
);

const mapStateToProps = store => ({
  status: store.status
});

PageLinks = connect(
  mapStateToProps,
  null
)(PageLinks);

export default PageLinks;
