import React from "react";
import ReactJson from "react-json-view";
import { connect } from "react-redux";

let Debug = props => (
  <div className="uk-container uk-text-left">
    <ReactJson src={props.respostas} />
    <ReactJson src={props.idiomas} />
    <ReactJson src={props.status} />
    <div
      className="uk-button uk-button-primary"
      onClick={() => console.log(props)}
    >
      toConsole
    </div>
  </div>
);

const mapStateToProps = store => ({
  respostas: store.respostas,
  idiomas: store.idiomas,
  status: store.status
});

Debug = connect(mapStateToProps)(Debug);

export default Debug;
