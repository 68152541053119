import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { updateValue, updateValueArray } from "../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberFormat from "react-number-format";

import RadioInputs from "../../components/RadioInputs";
import Title from "../../components/Title";
import PageLinks from "../../components/PageLinks";
import TextInput from "../../components/TextInput";
import dataSimNao from "../../data/dataSimNao";
import dataPeriodicidade from "../../data/dataPeriodicidade";
import CheckBoxes from "../../components/CheckBoxes";
import Subtitle from "../../components/Subtitle";

const dataCheckboxesBeneficios = [
  { label: "INSS", name: "beneficios_inss" },
  { label: "OAB", name: "beneficios_oab" },
  { label: "13o Salário", name: "beneficios_13o" },
  { label: "Seguro de Vida", name: "beneficios_segvida" },
  { label: "Celular Corporativo", name: "beneficios_celcorp" },
  { label: "Plano Odontológico", name: "beneficios_planoodonto" },
  { label: "Auxílio Transporte", name: "beneficios_auxtransporte" },
  { label: "Estacionamento", name: "beneficios_estacionamento" }
];

const location = "remuneracao";

let PerfilPessoal = ({ respostas, updateValue, updateValueArray }) => {
  const handleChange = e => {
    const { name, dataset, value } = e.target;
    // const value =
    //   e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const { label, indice } = dataset;
    if (e.target.type === "checkbox") {
      updateValueArray(name, value, label, indice, location);
    } else {
      updateValue(name, value, label, indice, location);
    }
  };

  const handleChangeMasked = (e, label, indice) => {
    const { name, value } = e.target;
    // const value =
    //   e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (e.target.type === "checkbox") {
      updateValueArray(name, value, label, indice, location);
    } else {
      updateValue(name, value, label, indice, location);
    }
  };

  const getResposta = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return "";
  };

  const getRespostasCheckbox = name => {
    const resposta = _.find(respostas, r => r.name === name);
    if (resposta) {
      return resposta.value;
    }
    return [];
  };

  return (
    <div>
      <div style={{ minHeight: "75vh" }}>
        <Title step={6} title="Pacote de Remuneração" />

        <Subtitle title="Remuneração" />
        <TextInput
          placeholder="Insira aqui a remuneração"
          label="Remuneração Fixa"
          name="remuneracao_fixa"
          value={getResposta("remuneracao_fixa")}
          onChange={handleChange}
          indice={1801}
        />

        <Subtitle title="Benefícios" />

        <CheckBoxes
          label="Benefícios"
          name="beneficios"
          other
          checkboxes={dataCheckboxesBeneficios}
          valueCheckboxes={getRespostasCheckbox("beneficios")}
          value={getResposta("beneficios_outros")}
          onChange={handleChange}
          indice={1802}
        />

        <RadioInputs
          label="Plano de Saúde?"
          name="beneficios_planosaude"
          radios={dataSimNao}
          onChange={handleChange}
          checked={getResposta("beneficios_planosaude")}
          value={getResposta("beneficios_planosaude")}
          indice={1809}
        />

        {getResposta("beneficios_planosaude") === "Sim" && (
          <div style={{ marginBottom: 20 }}>
            <TextInput
              label="Qual o Plano de Saúde?"
              name="beneficios_plano_nome"
              value={getResposta("beneficios_plano_nome")}
              onChange={handleChange}
              indice={1810}
              info="Informe o nome do plano e se é totalmente custeado pelo escritório/empresa ou coparticipativo"
            />
          </div>
        )}

        <RadioInputs
          label="Auxílio Refeição?"
          name="beneficios_auxrefeicao"
          radios={dataSimNao}
          onChange={handleChange}
          checked={getResposta("beneficios_auxrefeicao")}
          value={getResposta("beneficios_auxrefeicao")}
          indice={1811}
        />

        {getResposta("beneficios_auxrefeicao") === "Sim" && (
          <div style={{ marginBottom: 20 }}>
            <div className="uk-grid uk-grid-medium uk-flex-middle">
              <div className="uk-width-1-3@m uk-text-left">
                <div
                  className="uk-margin-large-left"
                  style={{ color: "#555555" }}
                >
                  Qual o Valor do Auxílio Refeição (Mensal)?
                </div>
              </div>
              <div className="uk-width-1-3@m">
                <NumberFormat
                  value={getResposta("beneficios_auxrefeicao_valor")}
                  name="beneficios_auxrefeicao_valor"
                  onChange={e =>
                    handleChangeMasked(
                      e,
                      "Qual o Valor do Auxílio Refeição (Mensal)?",
                      1812
                    )
                  }
                  thousandSeparator="."
                  prefix={"R$"}
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  className="uk-input"
                />
              </div>
            </div>
          </div>
        )}

        <RadioInputs
          label="Auxílio Acadêmico?"
          name="beneficios_auxacademico"
          radios={dataSimNao}
          onChange={handleChange}
          checked={getResposta("beneficios_auxacademico")}
          value={getResposta("beneficios_auxacademico")}
          indice={1813}
        />

        {getResposta("beneficios_auxacademico") === "Sim" && (
          <div style={{ marginBottom: 20 }}>
            <TextInput
              label="Qual o Valor do Auxílio Acadêmico?"
              name="beneficios_auxacademico_valor"
              value={getResposta("beneficios_auxacademico_valor")}
              onChange={handleChange}
              indice={1814}
            />
          </div>
        )}

        <RadioInputs
          label="Previdência Privada?"
          name="beneficios_prevprivada"
          radios={dataSimNao}
          onChange={handleChange}
          checked={getResposta("beneficios_prevprivada")}
          value={getResposta("beneficios_prevprivada")}
          indice={1815}
        />

        {getResposta("beneficios_prevprivada") === "Sim" && (
          <div style={{ marginBottom: 20 }}>
            <TextInput
              label="Qual o Valor da Previdência Privada?"
              name="beneficios_prevprivada_valor"
              value={getResposta("beneficios_prevprivada_valor")}
              onChange={handleChange}
              indice={1816}
            />
          </div>
        )}

        <RadioInputs
          label="Participação nos Lucros (PL)"
          name="remuneracao_pl"
          radios={dataSimNao}
          onChange={handleChange}
          checked={getResposta("remuneracao_pl")}
          value={getResposta("remuneracao_pl")}
          indice={1803}
        />

        {getResposta("remuneracao_pl") === "Sim" && (
          <div className="uk-margin-top uk-margin-bottom">
            <CheckBoxes
              label="Periodicidade do pagamento da PL"
              name="periodicidade_pl"
              checkboxes={dataPeriodicidade}
              valueCheckboxes={getRespostasCheckbox("periodicidade_pl")}
              onChange={handleChange}
              indice={1804}
            />
            <TextInput
              label="Média do valor das PL do período selecionado acima"
              name="remuneracao_pl_media"
              value={getResposta("remuneracao_pl_media")}
              onChange={handleChange}
              indice={1805}
            />
            <TextInput
              label="Critérios de Pagamento de PL"
              name="criterios_pl"
              value={getResposta("criterios_pl")}
              onChange={handleChange}
              indice={1806}
            />
          </div>
        )}

        <RadioInputs
          label="Bônus por Captação"
          name="remuneracao_bonus_captacao"
          radios={dataSimNao}
          onChange={handleChange}
          checked={getResposta("remuneracao_bonus_captacao")}
          value={getResposta("remuneracao_bonus_captacao")}
          indice={1807}
        />

        {getResposta("remuneracao_bonus_captacao") === "Sim" && (
          <TextInput
            label="Descreva a bonificação por captação"
            name="bonus_captacao_observacoes"
            value={getResposta("bonus_captacao_observacoes")}
            onChange={handleChange}
            indice={1808}
          />
        )}

        <TextInput
          label="Quais os descontos praticados?"
          name="remuneracao_descontos"
          value={getResposta("remuneracao_descontos")}
          onChange={handleChange}
          indice={1817}
        />
      </div>

      <PageLinks nextRoute="comportamental" prevRoute="perfilpessoal" />
    </div>
  );
};

const mapStateToProps = store => ({
  respostas: store.respostas
});

const mapDispatchToProps = dispatch => ({
  updateValue: (name, value, label, indice, location) =>
    dispatch(updateValue(name, value, label, indice, location)),
  updateValueArray: (name, value, label, indice, location) =>
    dispatch(updateValueArray(name, value, label, indice, location))
});

PerfilPessoal = connect(
  mapStateToProps,
  mapDispatchToProps
)(PerfilPessoal);

export default PerfilPessoal;
